var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "CCard",
            { staticClass: "mb-4" },
            [
              _c(
                "CCardBody",
                [
                  _c("div", [
                    _c("h4", { staticClass: "card-title mb-0" }, [
                      _vm._v("Average Order Report"),
                    ]),
                    _c("div", { staticClass: "small text-muted" }, [
                      _vm._v("Filter and analyze order statistics"),
                    ]),
                  ]),
                  _c(
                    "CCard",
                    {
                      staticClass: "filters my-3",
                      attrs: { "accent-color": "warning" },
                    },
                    [
                      _c("CCardBody", { staticClass: "p-2" }, [
                        _c(
                          "div",
                          { staticClass: "grid" },
                          [
                            _c("v-select", {
                              staticClass: "v-select-filter",
                              attrs: {
                                options: _vm.years,
                                searchable: false,
                                placeholder: "Select year...",
                                clearable: "",
                              },
                              model: {
                                value: _vm.averageReport.filters.year,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.averageReport.filters,
                                    "year",
                                    $$v
                                  )
                                },
                                expression: "averageReport.filters.year",
                              },
                            }),
                            _c("v-select", {
                              staticClass: "v-select-filter",
                              attrs: {
                                options: _vm.orderChannels,
                                searchable: false,
                                placeholder: "Order channel..",
                                clearable: "",
                              },
                              model: {
                                value: _vm.averageReport.filters.orderChannel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.averageReport.filters,
                                    "orderChannel",
                                    $$v
                                  )
                                },
                                expression:
                                  "averageReport.filters.orderChannel",
                              },
                            }),
                            _c("div", { staticClass: "form-check mt-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.averageReport.filters.multipleOrders,
                                    expression:
                                      "averageReport.filters.multipleOrders",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "multipleOrders",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.averageReport.filters.multipleOrders
                                  )
                                    ? _vm._i(
                                        _vm.averageReport.filters
                                          .multipleOrders,
                                        null
                                      ) > -1
                                    : _vm.averageReport.filters.multipleOrders,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.averageReport.filters
                                          .multipleOrders,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.averageReport.filters,
                                            "multipleOrders",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.averageReport.filters,
                                            "multipleOrders",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.averageReport.filters,
                                        "multipleOrders",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "multipleOrders" },
                                },
                                [
                                  _vm._v(
                                    " Only multiple orders (per customer) "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row mb-4 stats-cards" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "primary",
                            header: `${_vm.averageReport.data.totalCustomers}`,
                            text: "Total Customers",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "info",
                            header: `${_vm.averageReport.data.totalOrders}`,
                            text: "Total Orders",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "warning",
                            header: _vm.$options.filters.toCurrency(
                              _vm.averageReport.data.averageOrderAmount,
                              "remove_thousand_separator"
                            ),
                            text: "Average Order Amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "success",
                            header: `${_vm.averageReport.data.ordersPerCustomer}`,
                            text: "Orders per Customer",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("h5", [_vm._v("Order Distribution")]),
                        _c("CDataTable", {
                          attrs: {
                            items: _vm.orderDistributionItems,
                            fields: [
                              { key: "orderCount", label: "Order Count" },
                              { key: "customerCount", label: "Customer Count" },
                            ],
                            striped: "",
                            small: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("h5", [_vm._v("Additional Statistics")]),
                        _c("CDataTable", {
                          attrs: {
                            items: [_vm.averageReport.data],
                            fields: [
                              {
                                key: "minOrderAmount",
                                label: "Min Order Amount (€)",
                              },
                              {
                                key: "maxOrderAmount",
                                label: "Max Order Amount (€)",
                              },
                              {
                                key: "uniqueOrderDays",
                                label: "Unique Order Days",
                              },
                            ],
                            striped: "",
                            small: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "minOrderAmount",
                                fn: function ({ item }) {
                                  return [
                                    _c("td", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              item.minOrderAmount,
                                              "remove_thousand_separator"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "maxOrderAmount",
                                fn: function ({ item }) {
                                  return [
                                    _c("td", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              item.maxOrderAmount,
                                              "remove_thousand_separator"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1857072457
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("CSpinner", { attrs: { color: "primary" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }